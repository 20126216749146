<template>
  <div class="content">
    <template>
      <v-card
        :style="
          $vuetify.breakpoint.xs
            ? 'margin: 4px'
            : 'margin: 0 18px 0 18px'
        "
      >
        <base-loading v-if="loading" />
        <div v-else>
          <crud-list
            v-model="regionais"
            :headers="headers"
            :actions="actions"
            :permission="'list_regional'"
            :sort-by="['nome']"
            :sort-desc="[false]"
            export-pdf-xlsx
          >
            <template
              v-if="hasPermission('inserir_regional')"
              v-slot:btnCadastro
            >
              <v-col>
                <v-btn
                  block
                  class="text-none text-white"
                  color="primary"
                  title="Cadastrar nova regional"
                  rounded
                  @click="$router.push({ path: 'regionais/cadastrar' })"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-file-plus
                  </v-icon>
                  Cadastrar
                </v-btn>
              </v-col>
            </template>
          </crud-list>
        </div>
      </v-card>
    </template>
  </div>
</template>
<script>
  import store from '@/store'
  import RegionaisStore, { BOOTSTRAP, INATIVAR_REGIONAL } from '@/store/modules/regionais'
  import { mapState, mapActions, mapGetters } from 'vuex'
  export default {
    data () {
      return {
        actions: [
          {
            title: 'Editar regional',
            color: 'info darken-3',
            click: item => this.getRegional(item),
            icon: 'mdi-pencil',
            permission: 'editar_regional',
          },
          {
            title: 'Excluir Regional',
            color: 'red darken-3',
            click: item => this.INATIVAR_REGIONAL(item),
            icon: 'mdi-trash-can-outline',
            permission: 'excluir_regional',
          },
        ].filter(action => store.getters.hasPermission(action.permission)),
        headers: [
          { text: '', value: 'actions', sortable: false, groupable: false, floatingfilter: false, width: '40px' },
          { align: 'left', sortable: true, groupable: false, floatingfilter: true, text: 'Nome', value: 'nome', width: 'auto', report: true },
        ],
      }
    },
    computed: {
      ...mapState('regionais', ['loading', 'regionais']),
      ...mapGetters(['hasPermission']),
    },
    async created () {
      if (!this.$store.hasModule('regionais')) { this.$store.registerModule('regionais', RegionaisStore) }
      this.BOOTSTRAP()
    },
    methods: {
      ...mapActions('regionais', [BOOTSTRAP, INATIVAR_REGIONAL]),
      getRegional ({ id }) {
        this.$router.push({
          path: `/${this.$user.get().role}/regionais/alterar`,
          query: { id },
        })
      },
    },
  }
</script>
